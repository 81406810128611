import * as React from 'react';

const MovingCTA = () => (
  <article className="w-full max-w-5xl pt-12 mx-auto my-auto">
    <p className="text-6xl font-semibold leading-none uppercase font-display text-brand-blue">
      Reliable, <br />
      fast shipping <br />
      with <br />
      <span className="text-brand-orange">
        Frontline <br />
        Removals
      </span>
    </p>
  </article>
);

export default MovingCTA;
